.emailInput {
  width: 100%;
  padding: 5px 12px;
  border-radius: 8px;
  border: 1px solid var(--color-input-border);
  font-size: 14px;
  line-height: 22px;

  &::placeholder {
    opacity: 0.5;
    color: black;
  }
}
