.logo-section__title_span1 {
  font-size: 130px;
  padding-right: 50px;
  margin-top: 100px;
}
.logo-section__title_span2 {
  font-size: 110px;
  margin-top: 100px;
}

@media (max-width: 991px) {
  .logo-section__title_span1 {
    font-size: 90px;
    padding-right: 30px;
  }
  .logo-section__title_span2 {
    font-size: 70px;
  }
}

@media (max-width: 767px) {
  .logo-section__title_span1 {
    font-size: 60px;
    padding-right: 10px;
  }
  .logo-section__title_span2 {
    font-size: 45px;
  }
}
