.admin-panel {
  font-family: "Roboto", sans-serif !important;
  .text-primary-admin {
    color: var(--color-admin-primary);
  }

  .title {
    font-size: 20px;
  }

  .main {
    background-color: #f8f8f8;
    margin-block: 10px;
  }

  &__footer > p {
    font-size: 12px;

    .product-version {
      margin-left: 4px;
      opacity: 0.65;
    }
  }
}

.panel {
  width: clamp(546px, 85%, 1024px);
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  .header {
    background-color: var(--color-medium-gray-2);
    .title {
      color: rgba(0, 0, 0, 0.87);
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: center;
    }
  }

  .content {
    padding-top: 24px;
    padding-bottom: 16px;
    position: relative;

    .nav-item {
      padding: 6px 0;
    }

    .after::after {
      content: "";
      background-color: var(--color-admin-primary);
      height: 3px;
    }
    .search {
      display: flex;
      gap: 8px;
      .input_wrapper {
        display: flex;
        border: 1px solid var(--color-input-border);
        border-radius: 2px;

        input {
          border-right: 1px solid var(--color-input-border);
        }
      }
      .filter {
        position: relative;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-block: 5px;
        border: 1px solid var(--color-medium-gray-2);
        border-radius: 4px;
        cursor: pointer;

        &.active::after {
          content: "";
          width: 6px;
          height: 6px;
          background-color: var(--color-admin-primary);
          border-radius: 50%;
          position: absolute;
          top: 2px;
          right: 2px;
        }
      }
    }

    .table__wrapper {
      & > div {
        width: 100% !important;
        flex-grow: 1;
      }

      .pagination {
        align-self: center;
        .page-item {
          border: 1px solid transparent;
          &.active {
            font-weight: 600;
            color: var(--color-admin-primary);
            border: 1px solid var(--color-admin-primary);
            border-radius: 2px;
          }

          &.active .page-link {
            z-index: unset;
          }

          .page-link {
            font-weight: inherit;
            text-align: center;
            width: 24px;
            height: 24px;
            font-size: 14px;
            padding: 2px 0px;
            background-color: #fff;
            color: inherit;
            border: none;
            line-height: 22px;

            &:focus {
              box-shadow: none;
              border-radius: unset;
            }
          }

          &.muted .page-link > span {
            visibility: hidden;
            &::before {
              color: rgba(0, 0, 0, 0.25);
              font-size: 19px;
              visibility: visible;
              font-family: "Feather";
              content: "\e99b";
            }
          }
          &.prev .page-link,
          &.next .page-link {
            padding: 4px;
            & > span {
              visibility: hidden;
              &::before {
                content: "";
                display: inline-block;
                width: 12px;
                height: 12px;
                font-size: 12px;
                visibility: visible;
              }
            }
          }
          &.prev .page-link {
            & > span::before {
              content: url("../img/prev.svg");
            }
          }
          &.next .page-link {
            & > span::before {
              content: url("../img/next.svg");
            }
          }
          &.disabled {
            &.prev .page-link {
              & > span::before {
                content: url("../img/prev-disabled.svg");
              }
            }
            &.next .page-link {
              & > span::before {
                content: url("../img/next-disabled.svg");
              }
            }
          }
        }
      }
    }
  }
}

.BaseTable {
  box-shadow: unset;
  font-family: "Roboto", sans-serif !important;

  &__header,
  &__header > div {
    &-row {
      border-bottom: none;
      background-color: #fff;
      color: rgba(0, 0, 0, 0.5);
      line-height: 20px;
      text-align: left;
    }
  }

  &__body {
    scrollbar-gutter: stable;
    & > div {
      width: 100% !important;
    }
  }

  &__table {
    &-main {
      outline: none;
    }
  }
  &__expand-icon {
    margin-right: 12px;
    margin-left: 4px !important;
    visibility: hidden;
    color: var(--color-admin-primary);
    transition: none;
    display: flex;
    font-size: 13px !important;
    transform: rotate(0deg) !important;
    &::before {
      content: "\f078";
      font-family: "FontAwesome";
      visibility: visible;
    }
    &--expanded {
      transform: rotate(0deg) !important;
      &::before {
        content: "\f077";
        font-family: "FontAwesome";
        visibility: visible;
      }
    }
  }

  &__row {
    width: 100% !important;
    border-bottom: unset;
    overflow: visible !important;
    &--depth-0 {
      width: 976px !important;
      height: 56px;
    }
    &--depth-1 {
      width: 976px !important;
    }
    &-cell {
      &:first-child {
        padding-left: 8px !important;
        padding-right: 8px;
        display: flex;
        gap: 4px;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-medium-gray-2);
    }

    &--expanded {
      background-color: var(--color-medium-gray-4);
    }

    .dropdown {
      color: var(--color-admin-primary);
      cursor: pointer;
    }
    .subscription {
      width: 25px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: var(--color-medium-gray-4);
      text-transform: uppercase;
      font-size: 12px;
      border: 1px solid #eaeaea;
      border-radius: 2px;
      display: inline-block;
      &.active {
        border: 1px solid var(--color-admin-primary);
      }
    }

    .debt {
      color: var(--color-admin-primary);
    }

    .options {
      border: 1px solid var(--color-medium-gray-2);
      border-radius: 4px;
    }

    .text {
      display: flex;
      font-size: 12px;
      letter-spacing: 0.4px;
      text-align: left;
      line-height: 16px;
    }

    .details {
      width: 100%;
      background-color: var(--color-medium-gray-4);
      &__item {
        font-size: 12px;
        line-height: 20px;
      }
      &__header {
        display: flex;
        justify-content: space-between;
        padding: 12px 8px 8px 8px;
        .subscription-period {
          display: inline-block;
          background-color: var(--color-medium-gray-3);
          border-radius: 2px;
          padding-inline: 8px;
          margin-left: 4px;
        }
      }

      &__info {
        display: flex;
        padding: 6px 4px;
        background-color: var(--color-medium-gray-3);

        &-column {
          display: flex;
          flex-direction: column;
          padding-inline: 8px;
        }

        .value {
          padding-block: 4px;

          .more-detail {
            color: var(--color-admin-primary);
            cursor: pointer;
            .arrow {
              position: relative;
              bottom: -1px;
              margin-left: 5px;
              color: var(--color-admin-primary);
            }
          }
        }
      }
    }
  }
}

.BaseTable__table .BaseTable__body {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    border: 9.5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 15px;
    background-color: #e4e8eb;
  }

  &::-webkit-scrollbar:vertical {
    width: 24px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 6px;
    border: none;
    background-clip: unset;
  }

  &::-webkit-scrollbar-thumb {
    border: 9.5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 15px;
    background-color: #7d838a;

    &:hover {
      background-color: #666;
    }
  }
  &::-webkit-scrollbar-thumb:horizontal {
    border: none;
    background-clip: unset;
  }
  &::-webkit-resizer {
    display: none;
  }
}
