$spinner: red;
$background: white;
$size: 64px;

.loader-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  --ring-size: 8px;

  width: 64px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--color-admin-primary) 94%, #0000)
      top/var(--ring-size) var(--ring-size) no-repeat,
    conic-gradient(#0000 30%, var(--color-admin-primary));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - var(--ring-size)), #000 0);
  animation: spinner 1s infinite linear;
}
@keyframes spinner {
  100% {
    transform: rotate(1turn);
  }
}
