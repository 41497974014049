.custom-tabs {
  border-radius: 4px;
  background-color: var(--color-medium-gray-3);

  .tab-link {
    color: var(--color-admin-primary);
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 20px;
    padding: 5px 13px;
    transition: none;
    cursor: pointer;

    &.active {
      padding: 4px 12px;

      border-radius: 4px;
      background-color: $white-bg;
      border: 1px solid var(--color-medium-gray-2);

      &:hover {
        background-color: $white-bg !important;
      }
    }
  }
}

.user-payments {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: clamp(300px, 50vh, 716px);

  .payment-header {
    font-size: 12px;
    line-height: 15.6px;
    padding: 8px 8px;
  }

  .payment-num {
    color: var(--color-primary-black);
    font-weight: 500;
  }

  .payment-sum {
    color: var(--color-primary-black);

    .sum-value {
      border-radius: 2px;
      padding: 2px 8px;
      background-color: var(--color-medium-gray-2);
    }
  }

  .payments-list {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      border-radius: 10px;
      background-color: #e4e8eb;
    }

    &::-webkit-scrollbar:vertical {
      width: 4px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 6px;
      border: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #7d838a;

      &:hover {
        background-color: #666;
      }
    }
    &::-webkit-scrollbar-thumb:horizontal {
      border: none;
      background-clip: unset;
    }
    &::-webkit-resizer {
      display: none;
    }
  }

  .payment-col {
    font-size: 12px;
    line-height: 16px;
    background-color: var(--color-medium-gray-4);
    border-radius: 2px;

    .col-label {
      color: var(--color-medium-gray-1);
    }

    .col-value {
      color: var(--color-primary-black);
    }

    &.payment-col-with-arrow {
      background-color: var(--color-medium-gray-3);
    }
  }

  .pagination {
    align-self: center;
    .page-item {
      border: 1px solid transparent;
      &.active {
        font-weight: 600;
        color: var(--color-admin-primary);
        border: 1px solid var(--color-admin-primary);
        border-radius: 2px;
      }

      &.active .page-link {
        z-index: unset;
      }

      .page-link {
        font-weight: inherit;
        text-align: center;
        width: 24px;
        height: 24px;
        font-size: 14px;
        padding: 2px 0px;
        background-color: #fff;
        color: inherit;
        border: none;
        line-height: 22px;

        &:focus {
          box-shadow: none;
          border-radius: unset;
        }
      }

      &.muted .page-link > span {
        visibility: hidden;
        &::before {
          color: rgba(0, 0, 0, 0.25);
          font-size: 19px;
          visibility: visible;
          font-family: "Feather";
          content: "\e99b";
        }
      }
      &.prev .page-link,
      &.next .page-link {
        padding: 4px;
        & > span {
          visibility: hidden;
          &::before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            font-size: 12px;
            visibility: visible;
          }
        }
      }
      &.prev .page-link {
        & > span::before {
          content: url("../img/prev.svg");
        }
      }
      &.next .page-link {
        & > span::before {
          content: url("../img/next.svg");
        }
      }
      &.disabled {
        &.prev .page-link {
          & > span::before {
            content: url("../img/prev-disabled.svg");
          }
        }
        &.next .page-link {
          & > span::before {
            content: url("../img/next-disabled.svg");
          }
        }
      }
    }
  }
}
