/* Transition */
:root {
  --default-transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  --alt-font: "poppins", sans-serif;
  --primary-font: "Inter", sans-serif;
  --viewport-height: 100vh;
  --xl: 1600px;
  --lg: 1199px;
  --md: 991px;
  --sm: 767px;
  --xs: 575px;
  --xxs: 480px;
  --color-base: #000;
  --color-base-contrast: #fff;
  --color-primary: #d93535;
  --color-primary-contrast: #1b1f3bcc;
  --color-secondary: #333;
  --color-danger: #ef4444;

  --color-admin-primary: #fd3939;
  --color-btn-green: #6ae260;
  --color-btn-green-border: #55d749;
  --color-btn-green-hover: #28ee17;
  --color-confirm: #07c61a;

  --color-medium-gray-1: #00000080;
  --color-medium-gray-2: #e0e0e0;
  --color-medium-gray-3: #f0f0f0;
  --color-medium-gray-4: #f8f8f8;
  --color-primary-black: #212121;
  --color-input-border: #d9d9d9;

  --toastify-toast-width: auto;
}
