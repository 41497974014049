.change-language.dropdown {
  position: relative;

  .dropdown-menu {
    padding: 15px;
    visibility: visible;
    box-shadow: 0 0 35px rgb(0, 0, 0, 0.1);
    transition: none;

    &-icon {
      color: #232323;
    }

    .change-language-item {
      font-size: 12px;
      line-height: 30px;
      padding: 2px 0;
      color: #828282;

      &:hover {
        color: #232323;
      }

      &.active {
        color: #161616;
      }
    }
  }
}
