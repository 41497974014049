@tailwind base;

/* Vendors */
@import "swiper/swiper-bundle.min.css";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "atropos/atropos.css";
@import "react-18-image-lightbox/style.css";

/* Variable */
@import "./variable";

/* Core */
@import "./core/reset";
@import "./core/classes";
@import "./core/typography";

/* Components */
@import "./components/grid";
@import "./components/blog";
@import "./components/breadcrumbs";
@import "./components/clients";
@import "./components/countdown";
@import "./components/customiconwithtext";
@import "./components/dropcaps";
@import "./components/fancytext";
@import "./components/imagegallery";
@import "./components/infobanner";
@import "./components/instagram";
@import "./components/interactivebanners";
@import "./components/lists";
@import "./components/loader";
@import "./components/messagebox";
@import "./components/overlineiconbox";
@import "./components/piechart";
@import "./components/portfolio";
@import "./components/pricingtable";
@import "./components/products";
@import "./components/progressbar";
@import "./components/subscribe";
@import "./components/tab";
@import "./components/testimonialcarousel";
@import "./components/textslider";
@import "./components/changelanguage";
@import "./components/form";

// TODO: Optimize
/* start */
.dashboard-layout {
  // dashboard
  @import "layouts/dashboard/lbd/variables";
  @import "layouts/dashboard/lbd/mixins";

  @import "layouts/dashboard/lbd/typography";

  // Core CSS
  @import "layouts/dashboard/lbd/misc";
  @import "layouts/dashboard/lbd/sidebar-and-main-panel";
  @import "layouts/dashboard/lbd/buttons";
  @import "layouts/dashboard/lbd/inputs";

  @import "layouts/dashboard/lbd/admin-panel";
  @import "layouts/dashboard/lbd/user-panel";

  @import "layouts/dashboard/lbd/alerts";
  @import "layouts/dashboard/lbd/tables";

  @import "layouts/dashboard/lbd/checkbox-radio-switch";
  @import "layouts/dashboard/lbd/navbars";
  @import "layouts/dashboard/lbd/footers";

  // Fancy Stuff
  @import "layouts/dashboard/lbd/plugins/animate";
  @import "layouts/dashboard/lbd/dropdown";
  @import "layouts/dashboard/lbd/cards";
  @import "layouts/dashboard/lbd/chartist";
  @import "layouts/dashboard/lbd/responsive";
  @import "layouts/dashboard/lbd/bootstrap-switch";

  // Nucleo Icons
  @import "layouts/dashboard/lbd/partial-nucleo-icons";

  @import "layouts/dashboard/lbdr/plugins/react-notification-alert";
  @import "layouts/dashboard/lbdr/sidebar-and-main-panel";
}

#mobile-menu-dashboard-overflow {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: auto;
  right: 250px;
  content: "";
  z-index: 9999;
  overflow-x: hidden;
}
/* end */

body {
  background-color: var(--color-base);
  color: var(--color-base-contrast);

  /* полоса прокрутки (скроллбар) */
  &::-webkit-scrollbar {
    width: 10px; /* ширина для вертикального скролла */
    height: 4px; /* высота для горизонтального скролла */
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.149);
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.8);
  }

  /* Стрелки */

  &::-webkit-scrollbar-button {
    display: none;
  }
}
.text-red {
  color: var(--color-danger);
}
