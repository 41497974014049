.our-story-page {
  .process-step-style-05.process-step {
    .process-step-icon-box {
      padding: 45px 0 0;
    }
  }
}

.our-story-process-step-style {
  .process-step-description {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.our-story-fancy-text-box {
  .fancy-box-wrapper {
    padding-right: 40px;
    p {
      font-family: "Inter", sans-serif !important;
    }
  }
  .text-box-content {
    h4 {
      font-size: 2.3rem;
      line-height: 3rem;
    }
  }
}

.our-story-testimonial {
  .swiper-wrapper {
    cursor: url("../img/icon-move-light.png"), move !important;

    .swiper-slide {
      i {
        color: var(--color-primary);
      }
      span {
        &:nth-child(2) {
          color: var(--color-base-contrast);
        }
        &:nth-child(4) {
          background: var(--color-primary);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
